import * as React from 'react'
import styled from 'styled-components'
import { CoverImage } from '../image'
import { Button, IconButton } from '../button'
import { ResponsiveImage } from '../../data/types'
import { LinkItem } from '../../data/link'
import { Link } from '../link'

interface Props {
  tagline: string
  title: string
  description: string
  images: ResponsiveImage[]
  onRequestScrollDown: () => any
  button: LinkItem | null
}

const StyledHero = styled.header`
  background-color: var(--color-dark-navy);
  .container--grid {
    grid-row-gap: 24px;
  }

  @media screen and (min-width: 768px) {
    .container--grid {
      grid-row-gap: 48px;
    }
  }

  @media screen and (min-width: 834px) {
    .container--grid {
      min-height: 650px;
      height: calc(100vh - 105px); /* TODO: variables! */
      max-height: 975px; /* 1080 - 105 */
      grid-template-rows: 1fr auto 1fr auto;
    }
  }
`

const StyledContent = styled.div`
  padding-top: 10px;
  padding-right: 10vw;
  grid-column: 1 / -1;

  @media screen and (min-width: 768px) {
    padding-top: 50px;
    grid-column: 2 / -2;
  }

  @media screen and (min-width: 834px) {
    grid-column: 2 / 15;
    padding-right: 0;
    grid-row: 2;

    div {
      max-width: 370px;
    }
  }

  @media screen and (min-width: 1024px) {
    grid-column: 2 / 12;
  }
`

const StyledImage = styled.div`
  display: flex;
  margin-right: -16px;
  padding-left: 60px;
  grid-column: 1 / -1;
  margin-bottom: -70px;

  @media screen and (min-width: 768px) {
    grid-column: 2 / -2;
    margin-right: 0;
    margin-bottom: -140px;
  }

  @media screen and (max-width: 833px) {
    height: 322px;
  }

  @media screen and (min-width: 834px) {
    margin-bottom: 0;
    padding-left: 32px;
    grid-column: 15 / -1;
    margin-right: -16px;
    grid-row: span 4;
  }

  @media screen and (min-width: 1024px) {
    grid-column: 12 / -1;
  }

  @media screen and (min-width: 1280px) {
    grid-column: 12 / -2;
    margin-right: 0;
  }
`

const StyledScrollButton = styled.div`
  grid-column: 2 / 4;
  grid-row: 4;
  padding-bottom: 32px;

  @media screen and (max-width: 833px) {
    display: none;
  }
`

export default class Hero extends React.Component<Props, {}> {
  public render() {
    const {
      tagline,
      title,
      description,
      images,
      onRequestScrollDown,
      button,
    } = this.props
    return (
      <StyledHero className="color-n85">
        <div className="container container--grid">
          <StyledContent className="child-my-0">
            <div className="type-label-lg">{tagline}</div>
            <h1 className="type-h1 color-n95">{title}</h1>
            <div
              className="type-p-lg"
              dangerouslySetInnerHTML={{ __html: description }}
            />

            {button && (
              <Button
                as={Link}
                style={{ marginTop: 16 }}
                size="small"
                arrow
                appearance="secondary"
                to={button.location}
              >
                {button.text}
              </Button>
            )}
          </StyledContent>

          <StyledImage>
            {images.length ? <CoverImage {...images[0]} /> : null}
          </StyledImage>

          <StyledScrollButton>
            <IconButton appearance="tertiary" onClick={onRequestScrollDown}>
              <svg
                width="13"
                height="10"
                viewBox="0 0 13 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ transform: 'rotate(90deg)' }}
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.48711 0.659181L12.6966 5.1732L8.47326 9.35546L7.76962 8.64491L10.7845 5.65934L-8.74256e-07 5.65934L-6.9941e-07 4.65934L10.8501 4.65934L7.75577 1.34119L8.48711 0.659181Z"
                  fill="currentColor"
                />
              </svg>
            </IconButton>
          </StyledScrollButton>
        </div>
      </StyledHero>
    )
  }
}
